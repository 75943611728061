import React from 'react';
import { makeStyles } from '@mui/styles';
import { Collapse, List, ListItem, ListItemText } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Project } from '../../../store/project';
import { reOrderDocuments } from '../../../store/project';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TrashLinkedProjectRender } from '../TrashLinkedProjectRender/TrashLinkedProjectRender';
import theme from '../../../gatsby-theme-material-ui-top-layout/theme';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '960px',
    background: theme.palette.background.paper,
    padding: '0px',
  },
});

const nestedStyle = {
  paddingLeft: theme.spacing(7),
  paddingRight: theme.spacing(1),
  width: '100%',
  maxWidth: '960px',
  padding: '0px',
};

const labelStyle = {
  width: '100%',
  marginTop: '0px',
  marginBottom: '0px',
};

type TrashProjectDocumentRenderProps = {
  openProject: boolean;
  project: Project;
};
const TrashProjectDocumentRender: React.FC<TrashProjectDocumentRenderProps> = ({ openProject, project }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.draggableId === source.draggableId && destination.index === source.index) {
      return;
    }
    dispatch(reOrderDocuments(source.droppableId, source.index, destination.index));
  };

  return (
    <>
      <div className={classes.root}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Collapse component="li" in={openProject} timeout="auto" unmountOnExit>
            <Droppable droppableId={'tc-' + project.id}>
              {(provided: any) => (
                <List disablePadding ref={provided.innerRef} {...provided.droppableProps}>
                  {project.ruleSuites && project.ruleSuites.length > 0 ? (
                    <div>
                      {project.ruleSuites.map((ruleSuite, index) => {
                        if (ruleSuite.type == 'workspace') {
                          return (
                            <Draggable key={'tc-pdr-drag-' + ruleSuite.id} draggableId={ruleSuite.id} index={index}>
                              {(provided: any) => (
                                <ListItem
                                  button
                                  component="div"
                                  sx={nestedStyle}
                                  ContainerComponent="li"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <ListItemText sx={labelStyle} primary={ruleSuite.name} />
                                  <ListItemText sx={labelStyle} primary={ruleSuite.desc} />
                                </ListItem>
                              )}
                            </Draggable>
                          );
                        } else if (ruleSuite.type == 'project') {
                          return (
                            <TrashLinkedProjectRender
                              key={'tc-pdr-' + ruleSuite.id}
                              parentProjectId={project.id}
                              id={ruleSuite.id}
                              index={index}
                            />
                          );
                        }
                      })}
                      {provided.placeholder}
                    </div>
                  ) : null}{' '}
                </List>
              )}
            </Droppable>
          </Collapse>{' '}
        </DragDropContext>
      </div>
    </>
  );
};

export { TrashProjectDocumentRender };
