// @ts-nocheck
import React from 'react';
import { Router } from '@gatsbyjs/reach-router';
import AppLayout from '../components/layout/AppLayout';
import { TrashContainer } from '../components/old-version/TrashContainer/TrashContainer';

const Dashboard = () => {
  return (
    <AppLayout>
      <Router default="/">
        <TrashContainer path="/trash" isUserDashboard={true} />
      </Router>
    </AppLayout>
  );
};
export default Dashboard;
