import React from 'react';
import { makeStyles } from '@mui/styles';
import { Collapse, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import LockIcon from '@mui/icons-material/LockRounded';
import theme from '../../../gatsby-theme-material-ui-top-layout/theme';
import { Project, RuleSuite } from '../../../store/project';
import { TrashLinkedProjectRender } from '../TrashLinkedProjectRender/TrashLinkedProjectRender';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '960px',
    padding: '0px',
    background: theme.palette.background.paper,
  },
  lockIcon: {
    width: '4%',
    maxWidth: '960px',
    paddingLeft: '20px',
    paddingRight: '3px',
    background: theme.palette.background.paper,
  },
});

const nestedStyle = {
  paddingLeft: theme.spacing(1),
  width: '100%',
  maxWidth: '960px',
  padding: '0px',
};

const labelStyle = {
  paddingLeft: '15px',
  width: '100%',
  marginTop: '0px',
  marginBottom: '0px',
};

type TrashLinkedProjectDocumentRenderProps = {
  openProject: boolean;
  parentProjectId: string;
  project: Project;
};

const TrashLinkedProjectDocumentRender: React.FC<TrashLinkedProjectDocumentRenderProps> = ({
  openProject,
  parentProjectId,
  project,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Collapse component="li" in={openProject} timeout="auto" unmountOnExit>
          <List disablePadding key={'tc-tlpdr-pid-' + project.id} sx={nestedStyle}>
            {project.ruleSuites && project.ruleSuites.length > 0 ? (
              <div>
                {project.ruleSuites.map((ruleSuite: RuleSuite, index) => {
                  return ruleSuite.type == 'workspace' ? (
                    <ListItem key={'tc-tlpdr-pid-li-' + project.id} button component="div" sx={nestedStyle}>
                      <div className={classes.lockIcon}>
                        <ListItemIcon>
                          <LockIcon />
                        </ListItemIcon>
                      </div>
                      <ListItemText sx={labelStyle} primary={ruleSuite.name} />
                      <ListItemText sx={labelStyle} secondary={ruleSuite.desc} />{' '}
                    </ListItem>
                  ) : (
                    <TrashLinkedProjectRender
                      key={'tc-rlpr-rid-' + ruleSuite.id}
                      parentProjectId={parentProjectId}
                      id={ruleSuite.id}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : null}{' '}
          </List>
        </Collapse>{' '}
      </div>
    </>
  );
};
export { TrashLinkedProjectDocumentRender };
